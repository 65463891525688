.token-modal-content {
    width: 70%;
    height: 70%;
    background-color: #282c34;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    position: relative;
}