.modal {
    position: fixed;
    inset: 0; /* inset sets all 4 values (top right bottom left) much like how we set padding, margin etc., */
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    z-index: 9999999;
    padding: 40px 20px 20px;
    backdrop-filter: blur(8px);
}

.modal-content {
    width:70%;
    height: 80%;
    background-color: #282c34;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    position: relative;
}

.close-btn {
    position: absolute;
    background: transparent;
    outline: none;
    border: none;
    top: 10px;
    right: 5px;
    cursor: pointer;
}

.export-btn {
    position: relative;
    opacity: 0.85;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    width: 80%;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    background-color: #3f51b5;
    transition: all 0.2s ease 0s;
    outline: none;
    border: none;
}

.export-btn:hover {
    opacity: 1;
}
